export default {
  ssr: true,
  server: {
    host: '0.0.0.0',
    port: 3001,
  },
  head: {
    title: 'INDEFI',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { hid: 'description', name: 'description', content: '' },
      { name: 'format-detection', content: 'telephone=no' },
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/svg+xml',
        href: '/favicon.svg',
      },
    ],
  },
  components: true,
  css: [
    '@/assets/scss/main.scss',
  ],
  styleResources: {
    scss: [
      '@/assets/scss/variables.scss',
      '@/assets/scss/mixins.scss',
      '@/assets/scss/adaptive.scss',
    ],
  },
  plugins: [
    { src: '~/plugins/v-click-outside', ssr: false },
    { src: '~/plugins/v-calendar', ssr: false },
    { src: '~/plugins/vue-preloader', ssr: false },
    { src: '~/plugins/vue-notifications', ssr: false },
    '~/plugins/axios',
    '~/plugins/dayjs',
    '~plugins/vue-the-mask',
    '@/plugins/modals.js',
  ],
  buildModules: [
    '@nuxtjs/dotenv',
  ],
  modules: [
    'cookie-universal-nuxt',
    '@nuxtjs/svg',
    '@nuxtjs/style-resources',
    '@nuxtjs/axios',
    '@nuxtjs/dayjs',
    '@nuxtjs/i18n',
  ],
  axios: {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    proxyHeaders: true,
  },
  i18n: {
    locales: [
      { name: 'English', code: 'en', iso: 'en-US' },
      { name: 'Русский', code: 'ru', iso: 'ru-RU' },
    ],
    locale: 'en',
    defaultLocale: 'en',
    fallbackLocale: 'en',
    vueI18nLoader: true,
    strategy: 'no_prefix',
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: 'i18n_redirected',
      alwaysRedirect: false,
      redirectOn: 'root',
    },
  },
  dayjs: {
    locales: ['en', 'ru'],
    defaultLocale: 'en',
    plugins: ['utc', 'timezone'],
  },
  build: {
    transpile: ['vt-notifications', 'web3'], 
    extractCSS: true,
    optimization: {
      splitChunks: {
        chunks: 'all',
        maxSize: 400000, // Adjust chunk size as needed
      },
    },
    extend(config, { isDev, isClient }) {
      if (!isDev && isClient) {
        config.devtool = 'source-map';
      } else {
        config.devtool = 'eval-source-map';
      }
    },
  },
  render: {
    compressor: {
      threshold: 0, 
    },
    static: {
      maxAge: '1y', 
    },
  },
  performance: {
    gzip: true,
    prefetch: true,
    preconnect: true,
    preload: true,
  },
  hooks: {
    render: {
      errorMiddleware(app) {
        app.use((error, _req, _res, next) => {
          if (error) {
            console.log("Logged in errorMiddleware", error);
          }
          next(error);
        });
      },
    },
  },
  serverMiddleware: [
    '~/middleware/referral-redirect.js', // Middleware xử lý route referral
  ],
}